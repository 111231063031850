import Link from 'next/link';
import FAQItem from 'components/FaqItem';
import HTODisclaimer from 'components/HTODisclaimer';
import { FaqItem } from '@root/sanity/schema-types';
import { PortableText } from './sanity/serializers';
import { HTO_RETURN_WINDOW_IN_HOURS } from 'data/constants';

interface FAQSectionProps {
  pageName: string;
  items?: FaqItem[];
}

type DefaultFAQItemsProps = Omit<FAQSectionProps, 'items'>;

/**
 * A fallback we can use for sections of the site that use Sanity and the `faqSection` block,
 * but haven't been updated to specify custom children yet. (Showing these specific FAQ items
 *  was previously the default behavior of `faqSection`.)
 */
const DefaultFAQItems = ({ pageName }: DefaultFAQItemsProps) => (
  <>
    <FAQItem
      question="When should I place my order for my wedding?"
      trackerClass={`tracker-btn-${pageName}-place_order-220117-144838`}
    >
      <p>
        Honestly, as soon as you know your event date. If you need to rent for an event that is fewer than sixteen (16)
        days (21 days for AK, HI) from the time of order, please contact us for assistance setting up your event.
      </p>
    </FAQItem>

    <FAQItem
      question="How does the fitting work?"
      trackerClass={`tracker-btn-${pageName}-fitting_process-220117-144838`}
    >
      <p>
        All we need are a few answers to questions about your body profile including age, height, weight, shoe and jean
        size. Using our sizing algorithm, we'll determine the right fit for you.
      </p>

      <p>
        For customers aged fifteen (15) and under, we'll ask for specific measurements. All you need is a tape measure
        and a friend. If you don't have a tape measure, we can send you one. Then just follow our step by step guide and
        save the measurements in your profile.
      </p>
    </FAQItem>

    <FAQItem
      question="When will I get my order? Is there enough time?"
      trackerClass={`tracker-btn-${pageName}-order_arrival-220117-144838`}
    >
      <p>
        You will receive your items about 14 days before your event. This allows time for you to try the garments on and
        contact us with any fit issues. If needed, we will send FREE replacements to you right away.
      </p>
    </FAQItem>

    <FAQItem question="Do you have tuxes for kids?" trackerClass={`tracker-btn-${pageName}-kids_tuxes-220117-144838`}>
      <p>
        We do offer sizes for the little guys for some of our styles, and the process is the same.{' '}
        <Link href="/support" passHref>
          <a>Contact us</a>
        </Link>{' '}
        if you have specific questions! Create your look, enter their age and measurements, and checkout.
      </p>
    </FAQItem>

    <FAQItem
      question="Do you have big and tall sizes?"
      trackerClass={`tracker-btn-${pageName}-big_tall_sizes-220117-144838`}
    >
      <p>Absolutely. In most of our suits and tuxes we can go up to 66L jacket sizes and 60 inch waist pants.</p>
    </FAQItem>

    <FAQItem question="Can I see the look in advance?" trackerClass={`tracker-btn-${pageName}-hto-220117-144838`}>
      <p>
        Menguin offers a free Groom's{' '}
        <Link href="/app/hto/looks/build" passHref>
          <a>Home Try-On</a>
        </Link>
        <sup>*</sup>. You can build the look you want and we’ll ship the garments out to you for a free{' '}
        {HTO_RETURN_WINDOW_IN_HOURS}-hour trial.{' '}
        <Link href="/app/hto/looks/build" passHref>
          <a>Click here</a>
        </Link>{' '}
        or contact us if you are interested!
      </p>

      <HTODisclaimer textOnly />
    </FAQItem>
  </>
);

const FAQSection = ({ pageName, items }: FAQSectionProps) => {
  if (!items) {
    return (
      <div>
        <DefaultFAQItems pageName={pageName} />
      </div>
    );
  }

  return (
    <div>
      {items.map((item) => (
        <FAQItem question={item.question} trackerClass={item.trackerClass ?? ''}>
          <PortableText content={item.children} />
        </FAQItem>
      ))}
    </div>
  );
};

FAQSection.propTypes = {};

export default FAQSection;
